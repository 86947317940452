<template>
  <div class="goods_box">
    <div class="goods_item" v-for="(item,i) in objectArray" :key="i">
      <img
        class="goods_item_img"
        src="../../image/xiaDan.png"
      />
      <div class="goods_item_text">
        <div class="goods_item_name margin5">
          {{item.name}}
        </div>
        <div class="goods_item_number margin5">
          产品编号：{{item.number}}
        </div>
        <div class="goods_item_guige">
          <span>规格：</span>
           {{item.specifications}}
        </div>
        <div class="goods_item_operation">
          <div class="goods_item_operation_icon">
            <img
              class="imgShop imgShopp"
              src="../../image/love.png"
              alt=""
              srcset=""
            >
              <img
                class="imgShop"
                src="../../image/shop.png"
                alt=""
                srcset=""
              >
          </div>
          <div class="button">
            申请入仓
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  props: {
    objectArray: {
      type: Array,
      required: true
    }
  }
};
</script>
<style lang="less" scoped>
.goods_box {
  display: flex;
  flex-wrap: wrap;
  .goods_item {
    display: flex;
    justify-content: space-between;
    padding: 4px 0px 4px 6px;
    border: 1px solid #ffe8df;
    width: 364px;
    margin-bottom: 11px;
    box-sizing: border-box;
    margin-right: 21px;
    .goods_item_img {
      width: 154px;
      height: 109px;
    }
    .goods_item_text {
      width: 176px;
      .goods_item_name {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .goods_item_number {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #999999;
      }
      .margin5 {
        margin-bottom: 5px;
      }
      .goods_item_guige {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fe4202;
        span {
          color: #999999;
          font-size: 12px;
        }
      }
      .goods_item_operation {
        height: 23px;
        // background-color: #FE4202;
        display: flex;
        line-height: 23px;
        justify-content: space-between;
        align-items: center;
           margin-top: 15px;
        .goods_item_operation_icon {
       
          .imgShopp {
            margin-right: 12px;
          }
          .imgShop {
            width: 15px;
            height: 15px;
          }
        }
        .button {
          width: 76px;
          height: 23px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #f36a4a;
          background-color: #ffefea;
          border-radius: 10px;
          border:1px solid #f36a4a;
          text-align: center;
          line-height: 20px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
