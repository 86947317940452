<template>
  <div class="personal_containerer">
    <div class="personal_container">
      <div class="personal_box">
        <div class="personal_header_box">
          <div class="personal_header">
            <div class="personal_img">
              <img
                class="personal_UserImg"
                src="../../image/usertitle.png"
                alt=""
                srcset=""
              >
                <img
                  class="personal_UserImgg"
                  src="../../image/userVIP.png"
                />
            </div>
            <div class="personal_text">
              <div class="personal_textVIP marTop">
                代叔仓配黄金会员(133******13)
              </div>
              <div class="personal_text_name marTop">
                陪妳看最美的风景
              </div>
              <div class="personal_text_security marTop">
                <div class="personal_text_userName">
                  账户安全：
                </div>
                <div class="personal_text_userLine">
                  <div class="personal_text_userLine_save">

                  </div>
                </div>
                <div class="personal_text_userHeight">
                  高
                </div>
              </div>
            </div>
          </div>
          <div class="setUserPassword">
            <div class="setUser">
              账户设置
            </div>
            <div class="setUserOut">
              退出
            </div>
          </div>
        </div>
        <div class="personal_body">
          <div class="personal_body_page">
            <div class="personal_body_page_left">
              我的钱包：
            </div>
            <div class="personal_body_page_left">
              ￥1200.00
            </div>
          </div>
          <div class="personal_body_page">
            <div class="personal_body_page_left">
              结算金额：
            </div>
            <div class="personal_body_page_left">
              ￥54136.01
            </div>
          </div>
          <div class="personal_body_page">
            <div class="personal_body_page_left">
              我的仓票：
            </div>
            <div class="personal_body_page_left">
              10
            </div>
          </div>
        </div>
        <div class="myOrder">
          <div class="myOrder_title">
            <div class="myOrder_title_left">
              我的订单
            </div>
            <div class="myOrder_title_right">
              <div>
                全部订单
              </div>
              <div>
                1
              </div>
            </div>
          </div>
          <div class="myOrder_footer">
            <div class="myOrder_footer_item">
              <div class="myOrder_footer_item_text">
                待付款
              </div>
            </div>
            <div class="myOrder_footer_item">
              <img
                src="../../image/orderIocn2.png"
                alt=""
                srcset=""
              >
                <div class="myOrder_footer_item_text">
                  待发货
                </div>
            </div>
            <div class="myOrder_footer_item">
              <img
                src="../../image/orderIocn1.png"
                alt=""
                srcset=""
              >
                <div class="myOrder_footer_item_text">
                  待收货
                </div>
            </div>
            <div class="myOrder_footer_item">
              <img
                src="../../image/orderIocn3.png"
                alt=""
                srcset=""
              >
                <div class="myOrder_footer_item_text">
                  待评价
                </div>
            </div>
            <div class="myOrder_footer_item">
              <img
                src="../../image/orderIocn4.png"
                alt=""
                srcset=""
              >
                <div class="myOrder_footer_item_text">
                  已完成
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="personal_body">
        <div class="personal_body_item">
          <div class="personal_body_item_h1">
            根据浏览，猜您喜欢
          </div>
          <div class="personal_body_item_h2">
            <span>换一批</span>
            <img
              class="personal_body_item_h2_text"
              src="../../image/gengHuanIcon.png"
              alt=""
              srcset=""
            >
          </div>
        </div>
      </div>
      <!-- 产品 -->
      <div class="personal_footer">
        <goodsList :objectArray="objectArray"></goodsList>
      </div>
    </div>
    <div class="dateLogistics">
      <div class="dateLogistics_item item_Buttom20">
        <div class="dateLogistics_item_top ">
          代叔日历
        </div>
        <div class="dateLogistics_item_time">
          <div class="dateLogistics_item_Date">
            {{getDate}}
          </div>
          <div class="weekend">
            {{getWeek}}
          </div>
          <div class="weekend">
            {{ymd}}
          </div>
        </div>
      </div>

      <div class="dateLogistics_item dateLogistics_item_border">
        <div class="dateLogistics_item_top dateLogistics_item_color">
          便捷生活
        </div>
        <div class="dateLogistics_item_time">
          <div class="dateLogistics_item_time_item dateLogistics_item_time_flex">
            <div class="dateLogistics_item_time_flex_item">
              <div class="dateLogistics_item_time_flex_item_text">
                代叔站点
              </div>
              <div class="dateLogistics_item_time_flex_item_address">
                （查看站点位置）
              </div>
            </div>
            <div class="dateLogistics_item_time_flex_item">
              <div class="dateLogistics_item_time_flex_item_text">
                物流进程
              </div>
              <div class="dateLogistics_item_time_flex_item_address">
                （查看物流进程）
              </div>
            </div>
            <div class="dateLogistics_item_time_flex_item">
              <div class="dateLogistics_item_time_flex_item_text">
                代叔云仓
              </div>
              <div class="dateLogistics_item_time_flex_item_address">
                （查看可租仓库）
              </div>
            </div>
            <div class="dateLogistics_item_time_flex_item">
              <div class="dateLogistics_item_time_flex_item_text">
                代叔仓储
              </div>
              <div class="dateLogistics_item_time_flex_item_address">
                （查看系统管理）
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import goodsList from "../goodsLIst/goodsList";
import { dealWithTime, dealDate, getWeek } from "../../tools/tools.js";
export default {
  data() {
    return {
      objectArray: [
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        },
        {
          name: "阿米可牌罗勒酱复合调味料",
          number: "8005695004755",
          specifications: "130g*12瓶"
        }
      ],
      getDate: "",
      // 年月日
      ymd: "",
      getWeek: ""
    };
  },
  created() {
    this.getDate = dealDate(new Date());
    this.ymd = dealWithTime(new Date());
    this.getWeek = getWeek();
  },
  components: {
    goodsList
  }
};
</script>
<style lang="less" scoped>
.personal_containerer {
  background-color: #f0f2f5;
  display: flex;
}
.personal_container {
  width: 792px;
  height: 1100px;
  .personal_box {
    height: 344px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f0f2f5;
    .personal_header_box {
      display: flex;
      border-bottom: 1px solid #ffdbcc;
      background-color: #fff;
      padding: 25px 24px 10px 18px;
      width: 100%;
    }
    .personal_header {
      display: flex;

      width: 100%;
      .personal_img {
        position: relative;
        width: 71px;
        height: 71px;
        .personal_UserImg {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .personal_UserImgg {
          position: absolute;
          width: 26px;
          height: 25px;
          top: -14px;
          left: -1px;
        }
      }
      .personal_text {
        margin-left: 18px;
        .personal_textVIP {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff4102;
        }
        .personal_text_name {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
        .personal_text_security {
          display: flex;
          align-items: center;
          .personal_text_userName {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #666666;
          }
          .personal_text_userLine {
            width: 77px;
            height: 13px;
            border-radius: 10px;
            background-color: #cccccc;
            .personal_text_userLine_save {
              width: 62px;
              height: 13px;
              z-index: 2;
              border-radius: 10px;
              background-image: linear-gradient(#fba44a, #fe4103);
            }
          }
          .personal_text_userHeight {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ff4202;
            margin-left: 5px;
          }
        }
        .marTop {
          margin-bottom: 11px;
        }
      }
    }
    .setUserPassword {
      display: flex;
      width: 170px;
      height: 25px;

      z-index: 222;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-top: 27px;
      .setUserOut {
        padding-left: 18px;
        cursor: pointer;
      }
      .setUser {
        padding-right: 18px;
        border-right: 2px solid #c2c2c2;
        cursor: pointer;
      }
    }
    .personal_body {
      width: 100%;
      height: 67px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      line-height: 67px;
      padding-left: 20px;
      padding-right: 28px;
      .personal_body_page {
        display: flex;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
      }
    }
    .myOrder {
      margin-top: 21px;
      height: 103px;
      width: 100%;
      background-color: #fff;
      .myOrder_title {
        display: flex;
        height: 62px;
        justify-content: space-between;
        line-height: 62px;
        border-bottom: 1px solid #ffe1d5;
        padding-left: 10px;
        padding-right: 12px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
        .myOrder_title_right {
          display: flex;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #666666;
        }
      }
      .myOrder_footer {
        display: flex;
        justify-content: space-between;
        .myOrder_footer_item {
          border-right: 1px solid #ffe1d5;
          margin-top: 3px;
          // width: 156px;
          flex: 1;
          display: flex;
          height: 35px;
          justify-content: center;
          align-items: center;
          img {
            width: 25px;
          }
          .myOrder_footer_item_text {
            margin-left: 11px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #666666;
          }
        }
        .myOrder_footer_item:last-child {
          border-right: none;
        }
      }
    }
  }
  .personal_body {
    border-bottom: 1px solid #ffe8df;
    .personal_body_item {
      background-color: #fff;
      height: 73px;
      display: flex;
      line-height: 73px;
      padding-left: 20px;
      padding-right: 21px;
      justify-content: space-between;
      .personal_body_item_h1 {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .personal_body_item_h2 {
        cursor: pointer;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
        .personal_body_item_h2_text {
          height: 10px;
          width: 19px;
          margin-left: 12px;
        }
      }
    }
  }
  .personal_footer {
    padding-left: 19px;
    padding-top: 28px;
    background-color: #fff;
  }
}
.dateLogistics {
  .dateLogistics_item {
    width: 230px;
    height: 199px;
    border: 1px solid #fba44a;
    margin-left: 24px;

    .dateLogistics_item_top {
      height: 56px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      background-color: #f7986e;
      line-height: 56px;
      text-align: center;
    }
    .dateLogistics_item_color {
      background-color: #a88575;
    }
    .dateLogistics_item_time {
      .dateLogistics_item_Date {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff4d00;
        opacity: 0.9;
        text-align: center;
        margin-top: 46px;
        height: 22px;
        line-height: 22px;
      }
      .weekend {
        height: 14px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff4d00;
        opacity: 0.8;
        line-height: 14px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .dateLogistics_item_border {
    border: 1px solid #a88575;
  }
  .item_Buttom20 {
    margin-bottom: 20px;
    background-color: #efe3dd;
  }
  .dateLogistics_item_time_flex {
    display: flex;
    flex-wrap: wrap;
    .dateLogistics_item_time_flex_item {
      width: 114px;
      height: 70px;
      border: 1px solid #e8e5e4;
      box-sizing: border-box;
      background-color: #e9e7e6;
      // line-height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
        border:1px solid #dcd4d0;

      div {
        text-align: center;
      }
      .dateLogistics_item_time_flex_item_text {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #8f614c;
        line-height: 16px;
      }
      .dateLogistics_item_time_flex_item_address {
        height: 13px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #8f614c;
        opacity: 0.8;
        margin-top: 10px;
        line-height: 13px;
      }
    }
  }
}
</style>

