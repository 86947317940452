export function dealDate(date) {
  let Y = date.getFullYear()
  let M = date.getMonth() + 1 - 0 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
  let D = date.getDate()

  return D
}
export function dealWithTime(date) {
  let Y = date.getFullYear()
  let M = date.getMonth() + 1 - 0 >= 10 ? Number(date.getMonth()) + 1 : '0' + (Number(date.getMonth()) + 1)
  let D = date.getDate()
  let h = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
  let m = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
  let s = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
  return Y + '/' + M + '/' + D 
}
export function getWeek(){
  let data=[]
  var start=getDay(+7);
  var end=getDay();
  for(let i=6;i>=0;i--){
    data.push(getDay(+i))
  }
 var date=data.reverse()//得出一周的日期进行排序
 var week=date;
 var date=week;
 var pkc=[];/* 用于存储展示的日期数据 */
 var weekday=["星期日","星期一","星期二","星期三","星期四","星期五","星期六"];
 date.forEach((item,index)=>{//循坏日期
    var f=new Date(item);
    var week=f.getDay()//计算出星期几
  var str1=item.split('/');
  var strs=str1[1]+'/'+str1[2];

    var weeks=weekday[week]/* 将计算出来的时间带入数字得出中文 */
    var time= Math.round(new Date(item) / 1000)//时间戳转换
    var s={}//用于存储每个日期对象
    s.date=item;
    s.name=strs;
    s.week=weeks;
    s.times=time;
    pkc.push(s)
 })
 var same_week=pkc;
 //pkc存储着今天的年月日星期几，时间戳等
  var same_day=pkc[0].week;//今天的数据
  return same_day
  
}
function getDay(day){
  　　var today = new Date();
  　　var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
  　　today.setTime(targetday_milliseconds); //注意，这行是关键代码
  　　var tYear = today.getFullYear();
  　　var tMonth = today.getMonth();
  　　var tDate = today.getDate();
  　　tMonth = doHandleMonth(tMonth + 1);
  　　tDate =  doHandleMonth(tDate);
  　　return tYear+"/"+tMonth+"/"+tDate;
  }
  function doHandleMonth(month){
  　　var m = month;
  　　if(month.toString().length == 1){
    　　m =month;
  　　}
  　　return m;
  　　
  }